import { Injectable, computed } from '@angular/core';
import { BasicObjectSMI, ConversacionCompleta, ConversacionSimple, ConversacionVista, LearnworldsTitulo, Transcript, VideoLearnworlds } from '@interfaces/index';
import { ConsumirApi } from '@utils/index';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HabitService {

  private controlador = computed(() => 'habit');
  private api = new ConsumirApi(this.controlador());

  constructor(){}

  public obtenerTodos(idLider:number):Observable<BasicObjectSMI[]>{
    return from(this.api.get<BasicObjectSMI[]>(`${idLider}`));
  }

  public obtenerPorId(idLider:number, id:number): Observable<ConversacionSimple>{
    return from(this.api.get<ConversacionSimple>(`${idLider}/${id}`));
  }

  public crear(registro:ConversacionCompleta){
    return from(this.api.post<ConversacionCompleta, boolean>(``, registro));
  }

  public obtenerTranscripts(correo:string){
    return from(this.api.get<Transcript[]>(`obtenerReuniones/${correo}`));
  }

  public obtenerConversacionVista(idLider:number, idConversacion:number){
    return from(this.api.get<ConversacionVista>(`GetConversacion/${idLider}/${idConversacion}`));
  }

  public obtenerVideosAsignados(idConversacion:number){
    return from(this.api.get<VideoLearnworlds[]>(`GetVideosLearnworlds/${idConversacion}`));
  }

  public obtenerCategorias(){
    return from(this.api.get<string[]>(`GetCategorias`));
  }

  public obtenerVideosCategorias(categoria:string){
    return from(this.api.get<LearnworldsTitulo[]>(`GetVideosCategorias/${categoria}`));
  }

  public asignarCurso(curso:VideoLearnworlds){
    return from(this.api.post<VideoLearnworlds, boolean>(`asignarCurso`, curso));
  }

  public quitarCurso(curso:VideoLearnworlds){
    return from(this.api.post<VideoLearnworlds, boolean>(`quitarCurso`, curso));
  }

  public obtenerIdLearnworlds(correo:string){
    return from(this.api.getString(`obtenerIdLearnworlds/${correo}`));
  }
}
